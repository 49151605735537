import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Page404Component } from "./authentication/page404/page404.component";
import { AuthGuard } from "./core/guard/auth.guard";
import { Role } from "./core/models/role";
import { AuthLayoutComponent } from "./layout/app-layout/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./layout/app-layout/main-layout/main-layout.component";
const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "/authentication/signin", pathMatch: "full" },
      {
        path: "admin",
        canActivate: [AuthGuard],
        data: {
          role: Role.Admin,
        },
        loadChildren: () =>
          import("./admin/admin.module").then((m) => m.AdminModule),
      },
      {
        path: "doctor",
        canActivate: [AuthGuard],
        data: {
          role: Role.Doctor,
        },
        loadChildren: () =>
          import("./doctor/doctor.module").then((m) => m.DoctorModule),
      },
      {
        path: "patient",
        canActivate: [AuthGuard],
        data: {
          role: Role.Patient,
        },
        loadChildren: () =>
          import("./patient/patient.module").then((m) => m.PatientModule),
      },
      {
        path: "calendar",
        loadChildren: () =>
          import("./calendar/calendar.module").then((m) => m.CalendarsModule),
      },
      {
        path: "task",
        loadChildren: () =>
          import("./task/task.module").then((m) => m.TaskModule),
      },
      {
        path: "contacts",
        loadChildren: () =>
          import("./contacts/contacts.module").then((m) => m.ContactsModule),
      },
      {
        path: "email",
        loadChildren: () =>
          import("./email/email.module").then((m) => m.EmailModule),
      },
      {
        path: "calendar",
        loadChildren: () =>
          import("./apps/apps.module").then((m) => m.AppsModule),
      },
      {
        path: "apps",
        loadChildren: () =>
          import("./apps/apps.module").then((m) => m.AppsModule),
      },
      {
        path: "widget",
        loadChildren: () =>
          import("./widget/widget.module").then((m) => m.WidgetModule),
      },
      {
        path: "ui",
        loadChildren: () => import("./ui/ui.module").then((m) => m.UiModule),
      },
      {
        path: "forms",
        loadChildren: () =>
          import("./forms/forms.module").then((m) => m.FormModule),
      },
      {
        path: "tables",
        loadChildren: () =>
          import("./tables/tables.module").then((m) => m.TablesModule),
      },
      {
        path: "media",
        loadChildren: () =>
          import("./media/media.module").then((m) => m.MediaModule),
      },
      {
        path: "charts",
        loadChildren: () =>
          import("./charts/charts.module").then((m) => m.ChartsModule),
      },
      {
        path: "timeline",
        loadChildren: () =>
          import("./timeline/timeline.module").then((m) => m.TimelineModule),
      },
      {
        path: "icons",
        loadChildren: () =>
          import("./icons/icons.module").then((m) => m.IconsModule),
      },
      {
        path: "extra-pages",
        loadChildren: () =>
          import("./extra-pages/extra-pages.module").then(
            (m) => m.ExtraPagesModule
          ),
      },
      {
        path: "maps",
        loadChildren: () =>
          import("./maps/maps.module").then((m) => m.MapsModule),
      },
      {
        path: "multilevel",
        loadChildren: () =>
          import("./multilevel/multilevel.module").then(
            (m) => m.MultilevelModule
          ),
      },
      {
        path: "param",
        loadChildren: () =>
          import("./component/parametres/parametres.module").then((m) => m.ParametresModule),
      },
      {
        path: "offre",
        loadChildren: () =>
          import("./component/offre/offre.module").then((m) => m.OffreModule),
      },
      {
        path: "selection",
        loadChildren: () =>
          import("./component/selection/selection.module").then((m) => m.SelectionModule),
      },
      {
        path: "contrat",
        loadChildren: () =>
          import("./component/contrat/contrat/contrat.module").then((m) => m.ContratModule),
      },
      {
        path: "abandon",
        loadChildren: () =>
          import("./component/abandon/abandon.module").then((m) => m.AbandonModule),
      },
      {
        path: "desistement",
        loadChildren: () =>
          import("./component/desistement/desistement.module").then((m) => m.DesistementModule),
      },
      {
        path: "suivi",
        loadChildren: () =>
          import("./component/suivi/suivi.module").then((m) => m.SuiviModule),
      },
      {
        path: "evaluation",
        loadChildren: () =>
          import("./component/evaluation/evaluation.module").then((m) => m.EvaluationModule),
      },
      {
        path: "dossier",
        loadChildren: () =>
          import("./component/dossier/dossier.module").then((m) => m.DossierModule),
      },
      {
        path: "pre-inscription",
        loadChildren: () =>
          import("./component/pre-inscription/pre-inscription.module").then((m) => m.PreInscriptionModule),
      },
      {
        path: "paiement",
        loadChildren: () =>
          import("./component/reglement-apprenti/reglement-apprenti.module").then((m) => m.ReglementApprentiModule),
      },
      {
        path: "rapport",
        loadChildren: () =>
          import("./component/etat/etat.module").then((m) => m.EtatModule),
      },
      {
        path: "utiletprofil",
        loadChildren: () =>
          import("./component/administration/administration.module").then((m) => m.AdministrationModule),
      },
    ],
  },
  {
    path: "authentication",
    component: AuthLayoutComponent,
    loadChildren: () =>
      import("./authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: "**", component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
